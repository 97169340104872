import React from "react"
import Lightbox from "yet-another-react-lightbox"

import "yet-another-react-lightbox/styles.css"

const ImageLightbox = ({ buttonText, slides }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <button
        className="button is-text"
        type="button"
        onClick={() => setOpen(true)}
      >
        {buttonText}
      </button>
      <Lightbox
      styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" } }}
        open={open}
        close={() => setOpen(false)}
        slides={slides}
      />
    </>
  )
}

export default ImageLightbox
