import React from "react"
import { Link } from "gatsby"
import { RiSecurePaymentFill } from "react-icons/ri"

import Layout from "../components/layout.component"
import Seo from "../components/seo.component"

import imgSerLogo from "../images/course-logo-ser.jpg"
import imgPpsLogo from "../images/course-logo-pps.jpg"
import imgCagLogo from "../images/course-logo-cag.jpg"
import imgCurLogo from "../images/course-logo-cur.jpg"
import cgSlide1 from "../images/curriculum-guide-preview-1.jpg"
import cgSlide2 from "../images/curriculum-guide-preview-2.jpg"
import cgSlide3 from "../images/curriculum-guide-preview-3.jpg"
import cgSlide4 from "../images/curriculum-guide-preview-4.jpg"
import cgSlide5 from "../images/curriculum-guide-preview-5.jpg"
import cagSlide1 from "../images/college-audition-guide-preview-1.jpg"
import cagSlide2 from "../images/college-audition-guide-preview-2.jpg"
import cagSlide3 from "../images/college-audition-guide-preview-3.jpg"
import cagSlide4 from "../images/college-audition-guide-preview-4.jpg"

import "./guides.styles.scss"
import ImageLightbox from "../components/image-lightbox"

const TopicsPage = ({ location }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <Layout className="topics" pathname={location.pathname}>
      <Seo
        title="Guides"
        description="Explore methods that enhance your private studio offerings, build community, and produce profit for your private music studio through a selection of curated guides. Building a music curriculum, driving music studio profit, auditioning for college, and more."
      />
      <section className="hero topics__hero is-large">
        <div className="container hero-body">
          <h1 className="title is-size-2-mobile is-size-1-tablet has-text-shadow">
            Unlocking Your Studio's Potential
          </h1>
        </div>
      </section>
      <section className="topics__intro">
        <div className="container container--text">
          <div className="content has-text-centered">
            <h1 className="title">
              Explore methods that enhance your private studio offerings, build
              community, and produce profit for your private music studio
            </h1>
          </div>
        </div>
      </section>
      <section className="topics__topics">
        <div className="container">
          <div className="columns is-flex-wrap-wrap is-centered">
            <div className="column is-full-mobile is-half-tablet is-one-quarter-widescreen is-flex is-flex-direction-column">
              <div className="topics__topic has-box-shadow is-flex is-flex-direction-column is-flex-grow-1 is-75-percent-mobile-xl">
                <figure className="image">
                  <img
                    src={imgCurLogo}
                    alt=""
                    role="presentation"
                    className="is-flex-grow-0"
                  />
                </figure>
                <div className="topic__content content is-flex-grow-1 is-size-6 mt-2">
                  <h2>Curriculum Guide</h2>
                  <p>
                    <span className="has-text-grey-light">24-Page PDF</span>
                  </p>
                  <p>
                    Explore book options to use with your private students and
                    find out the sequence of materials that the Parasky Flute
                    Studios have implemented with success.
                  </p>
                  <ImageLightbox
                    buttonText="Preview the Curriculum Guide"
                    slides={[
                      { src: cgSlide1 },
                      { src: cgSlide2 },
                      { src: cgSlide3 },
                      { src: cgSlide4 },
                      { src: cgSlide5 },
                    ]}
                  />
                  <div className="mt-auto is-flex is-flex-direction-column">
                    <div className="is-flex is-align-items-center is-justify-content-center	">
                      <span className="is-size-3 has-text-weight-light">$</span>
                      <span className="is-size-2 has-text-weight-semibold">
                        19.99
                      </span>
                    </div>
                    <a
                      className="button is-info is-uppercase is-fullwidth has-text-weight-bold"
                      href="https://link.waveapps.com/tb33kk-kpsmkd"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <RiSecurePaymentFill
                        size="1.4rem"
                        style={{ marginRight: ".5rem" }}
                      />{" "}
                      Purchase this Guide
                    </a>
                    <div className="mt-2 is-size-7 is-italic has-text-centered">
                      Purchase receipt will contain a URL to download the guide.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-full-mobile is-half-tablet is-one-quarter-widescreen is-flex is-flex-direction-column">
              {/* SER wave link https://link.waveapps.com/vpd4qu-jwe239 */}
              <Link
                to="/subscribe"
                className="topics__topic has-box-shadow is-flex is-flex-direction-column is-flex-grow-1 is-75-percent-mobile-xl"
              >
                <figure className="image">
                  <img
                    src={imgSerLogo}
                    alt=""
                    role="presentation"
                    className="is-flex-grow-0"
                  />
                </figure>
                <div className="topic__content content is-flex-grow-1 is-size-6 mt-2">
                  <h2>Studio Ensemble Revolution</h2>
                  <span className="has-text-grey-light">Coming Soon</span>
                  <p>
                    This guide is the answer to all your questions about running
                    a studio ensemble program. Learn how to get started,
                    financial considerations and how to grow your program.
                  </p>
                  <button className="button is-info is-uppercase is-fullwidth has-text-weight-semibold">
                    Get notified
                  </button>
                </div>
              </Link>
            </div>
            <div className="column is-full-mobile is-half-tablet is-one-quarter-widescreen is-flex is-flex-direction-column">
              {/* Need wave link from dan */}
              <Link
                to="/subscribe"
                className="topics__topic has-box-shadow is-flex is-flex-direction-column is-flex-grow-1 is-75-percent-mobile-xl"
              >
                <figure className="image">
                  <img
                    src={imgPpsLogo}
                    alt=""
                    role="presentation"
                    className="is-flex-grow-0"
                  />
                </figure>
                <div className="topic__content content is-flex-grow-1 is-size-6 mt-2">
                  <h2>Profitable Private Studios</h2>
                  <p>
                    <span className="has-text-grey-light">Coming Soon</span>
                  </p>
                  <p>
                    Take an in-depth look at the tools, techniques and
                    strategies that can be used to make your private studio
                    profitable. Scheduling strategies, organizational tools and
                    financial tips will be explored.
                  </p>
                  <button className="button is-info is-uppercase is-fullwidth has-text-weight-semibold">
                    Get notified
                  </button>
                </div>
              </Link>
            </div>
            <div className="column is-full-mobile is-half-tablet is-one-quarter-widescreen is-flex is-flex-direction-column">
              <div className="topics__topic has-box-shadow is-flex is-flex-direction-column is-flex-grow-1 is-75-percent-mobile-xl">
                <figure className="image">
                  <img
                    src={imgCagLogo}
                    alt=""
                    role="presentation"
                    className="is-flex-grow-0"
                  />
                </figure>
                <div className="topic__content content is-flex-grow-1 is-size-6 mt-2">
                  <h2>College Audition Guide</h2>
                  <p>
                    <span className="has-text-grey-light">15-Page PDF</span>
                  </p>
                  <p>
                    A comprehensive curriculum for flute students to follow for
                    applying to college as a music major.
                  </p>
                  <ImageLightbox
                    buttonText="Preview the College Audition Guide"
                    slides={[
                      { src: cagSlide1 },
                      { src: cagSlide2 },
                      { src: cagSlide3 },
                      { src: cagSlide4 },
                    ]}
                  />
                  <div className="mt-auto is-flex is-flex-direction-column">
                    <div className="is-flex is-align-items-center is-justify-content-center	">
                      <span className="is-size-3 has-text-weight-light">$</span>
                      <span className="is-size-2 has-text-weight-semibold">
                        14.99
                      </span>
                    </div>
                    <a
                      className="button is-info is-uppercase is-fullwidth has-text-weight-bold"
                      href="https://link.waveapps.com/eb2kqb-f39a9k"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <RiSecurePaymentFill
                        size="1.4rem"
                        style={{ marginRight: ".5rem" }}
                      />{" "}
                      Purchase this Guide
                    </a>
                    <div className="mt-2 is-size-7 is-italic has-text-centered">
                      Purchase receipt will contain a URL to download the guide.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TopicsPage
